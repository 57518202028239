import React, { ReactElement } from "react";
import TrackEmbed, { TrackEmbedProps } from "../components/TrackEmbed";

const Listen = (): ReactElement => {
  const trackProps: TrackEmbedProps[] = [
    {
      title: "Vorstellung",
      id: "1990402191",
    },
    {
      title: "Cold Snap",
      id: "1938023156",
    },
    { title: "Here", id: "1934032580" },
    { title: "Now", id: "1934032571" },
    { title: "Lucky", id: "1805121957" },
  ];

  return (
    <div className="page-container">
      {/* <SoundCloudButton /> */}
      <div className="media-list-flex">
        {trackProps.map((props: TrackEmbedProps) => (
          <TrackEmbed key={props.title} title={props.title} id={props.id} />
        ))}
      </div>
    </div>
  );
};

export default Listen;
